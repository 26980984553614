import React from 'react'
import Highlights from 'components/highlights'
import Statistic from 'components/statistic'

export default () => (
  <Highlights
    title="At a glance"
  >
    <Statistic
      size="large"
      text="Underwriting capacity of the current 2024 portfolio"
      number="£507m"
      numberColor="white"
      borderColor="tan"
    />

    <Statistic
      size="large"
      text="Net tangible asset value"
      number="189p"
      numberColor="white"
      borderColor="tan"
    />

    <Statistic
      size="large"
      text="Outperformance against Lloyd’s market (last five years)"
      number="9.0%"
      numberColor="white"
      borderColor="tan"
    />
  </Highlights>
)